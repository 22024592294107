import { AppThunk } from '../types';
import {
  Configuration,
  Member,
  ModerationStatus,
} from '@wix/ambassador-reviews-v1-enriched-live-site-review/types';
import { Entity } from '../app-state/app-state-types';
import { Contact, ReviewContent } from '~commons/types';
import { FormErrorType } from '../form/form-types';
import { toFormError } from '../form/form-errors';
import { requestLogin } from '../../utils/request-login';
import { navigateToThankYou } from './navigation-actions';
import { ApplicationError } from '~/components/CollectionWidget/common/types';
import { AmbassadorHTTPError } from '@wix/ambassador/dist/src/runtime/http';
import {
  biReviewPublishSuccess,
  biReviewSubmittedForApproval,
} from '~/components/CollectionWidget/common/store/actions/bi-actions';

export const SET_INITIAL_DATA = 'SET_INITIAL_DATA' as const;

export const setInitialData = (payload: {
  entity: Entity;
  configuration: Configuration;
  currentMember: Member | undefined;
}) => ({
  type: SET_INITIAL_DATA,
  payload,
});

export const FETCH_INITIAL_DATA_FAILURE = 'FETCH_INITIAL_DATA_FAILURE' as const;

const fetchInitialDataFailure = () => ({
  type: FETCH_INITIAL_DATA_FAILURE,
});

export const CREATE_REVIEW_REQUEST = 'CREATE_REVIEW_REQUEST' as const;
export const CREATE_REVIEW_FAILURE = 'CREATE_REVIEW_FAILURE' as const;
export const CREATE_REVIEW_CANCEL = 'CREATE_REVIEW_CANCEL' as const;

export const createReviewRequest = () => ({ type: CREATE_REVIEW_REQUEST });

export const createReviewFailure = (payload: FormErrorType) => ({
  type: CREATE_REVIEW_FAILURE,
  payload,
});

export const createReviewCancel = () => ({ type: CREATE_REVIEW_CANCEL });

export const fetchInitialData =
  ({ namespace, entityId }: { namespace: string; entityId: string }): AppThunk =>
  async (dispatch, getState, { api }) => {
    const { baseParams } = getState();
    const reviewRequestId =
      baseParams.origin.type === 'REVIEW_REQUEST' ? baseParams.origin.reviewRequestId : undefined;
    const result = await api.fetchInitialData({ namespace, entityId, reviewRequestId });
    if (result.type === 'error') {
      dispatch(fetchInitialDataFailure());
      throw result.error;
    }
    const { entity, existingUserReview, currentMember, configuration } = result.value;

    if (existingUserReview && existingUserReview.id) {
      const reviewDeepLink = buildReviewDeeplink({
        namespace,
        entity,
        reviewId: existingUserReview.id,
      });
      return dispatch(navigateToThankYou(reviewDeepLink, 'review-exists'));
    }

    dispatch(
      setInitialData({
        entity,
        configuration,
        currentMember,
      }),
    );
  };

const buildReviewDeeplink = ({
  reviewId,
  namespace,
  entity,
}: {
  reviewId: string;
  namespace: string;
  entity: Entity;
}) => {
  const entityUrl = new URL(new URL(entity.url).searchParams.get('d') ?? entity.url);
  entityUrl.searchParams.append('wrDeepNs', namespace);
  entityUrl.searchParams.append('wrDeepResId', entity.id);
  entityUrl.searchParams.append('wrDeepRevId', reviewId);
  return entityUrl.toString();
};

export const createReview =
  ({ content, contact = {} }: { content: ReviewContent; contact?: Partial<Contact> }): AppThunk =>
  async (dispatch, getState, { wixCodeApi, api }) => {
    dispatch(createReviewRequest());
    const state = getState();
    const { namespace, entityId, origin } = state.baseParams;

    if (state.appState.type !== 'READY' || !namespace || !entityId) {
      dispatch(createReviewFailure('UNKNOWN'));
      throw new ApplicationError('Missing required data!', {
        namespace,
        entityId,
        'state.appState.type': state.appState.type,
      });
    }
    const { entity, configuration } = state.appState;

    const result = await api.createReview({
      namespace,
      content,
      entityId,
      origin,
      contact,
      isModerationEnabled: !!configuration.moderateContent,
    });
    if (result.type === 'error') {
      const failureReason = toFormError(result.error as AmbassadorHTTPError);
      switch (failureReason) {
        case 'CONTACT_BELONGS_TO_MEMBER':
          dispatch(createReviewCancel());
          return requestLogin(wixCodeApi);
        default:
          dispatch(createReviewFailure(failureReason));
          throw result.error;
      }
    }
    const review = result.value;
    const reviewsDeeplink = buildReviewDeeplink({
      namespace,
      entity,
      reviewId: review.id,
    });
    if (review.moderation?.moderationStatus !== ModerationStatus.APPROVED) {
      dispatch(biReviewSubmittedForApproval(review));
      dispatch(navigateToThankYou(reviewsDeeplink, 'review-pending'));
    } else {
      dispatch(biReviewPublishSuccess(review));
      dispatch(navigateToThankYou(reviewsDeeplink));
    }
  };

export const requestLoginAction =
  (): AppThunk =>
  (_dispatch, _getState, { wixCodeApi }) =>
    requestLogin(wixCodeApi);

type SetInitialDataAction = ReturnType<typeof setInitialData>;
type CreateReviewRequestAction = ReturnType<typeof createReviewRequest>;
type CreateReviewFailureAction = ReturnType<typeof createReviewFailure>;
type CreateReviewCancelAction = ReturnType<typeof createReviewCancel>;
type FetchInitialDataFailureAction = ReturnType<typeof fetchInitialDataFailure>;

export type CommonActions =
  | SetInitialDataAction
  | CreateReviewRequestAction
  | CreateReviewFailureAction
  | CreateReviewCancelAction
  | FetchInitialDataFailureAction;
